import { PaymentStatus } from '@/services/utils/order';
import type { TimeRangeTypes } from '@/components/TimeRange/Form/types';
import { ITransaction } from '@/views/Payments';
import { OrderStatusEnum } from '@/views/QsrOrders/types';
import { IQrRow } from '../QRCode/types';

export enum IDinerFeeOption {
    HideAll = 'hideAll',
    HideDashboard = 'hideDashboard',
    HideOrders = 'hideOrders',
    HidePayments = 'hidePayments',
    HideTableView = 'hideTableView',
}

interface IConfig {
    dinerFeeTooltipText?: string;
    showDinerFeeTooltip?: string;
    hideTableName?: boolean;
    hideTableId?: boolean;
    billPageDescriptionMode?: string;
    disableSplitWithPromo?: boolean;
    previewBill: boolean;
    vendorOrderKeys: string[];
    vendorPaymentKeys: string[];
    vendorShowBestsellingItems: boolean;
    dashboardTooltipEnabled: boolean;
    receiptBuilderEnabled: boolean;
    isDashboardRefundCardVisible: boolean;
    isVendorPaymentDateFilterEnabled: boolean;
    enableShiftMaskforVendorAnalyst: boolean;
    switchLanguageByFlag: boolean;
    abn?: string;
    openingHour?: string;
    closingHour?: string;
    legalBusinessName?: string;
    vatRegistrationNumber?: string;
    receiptBannerTitle?: string;
    receiptBannerDescription?: string;
    vendorPaymentPrint?: boolean;
    vendorQrOnReceipt?: boolean;
    newDashboardEnabled?: boolean;
    selectedDashboardKeys?: string[];
    disableTipForVendorUsers?: boolean;
    qsrTaxManagement?: boolean;
    modifiedTimezone?: string;
    enableBrandScheduler?: boolean;
    vendor_vpos_enable_share?: boolean;
    vendor_enableTipCommission?: boolean;
    enable_payment_link?: boolean;
    paymentLinkMaxAmount?: number;
    view_payment_link_table?: string[];
    view_payment_link_table_multi_use?: string[];
    paymentLinkDisableMultiUse?: boolean;
    enableForexBadge?: boolean;
    enableQrGroup?: boolean;
    vendor_enableReview?: boolean;
    VendorTableViewKeys?: string[];
    paymentLinkSchedulingEnabled?: boolean;
    enableBrandEmailVerificationBanner?: boolean;
    transactionDashboardEnabled?: boolean;
    enableLinkit?: 'show' | 'hide';
    defaultDashboardIsTransaction?: boolean;
    releaseToggle_reportsManagement?: boolean;
    isTransactionPoweredApi?: boolean;
    reviewAdditionalColumns?: string[];
    showCustomerDetailOnReviews?: boolean;
    enableExternalPaidFlagInTableView?: string;
    enable_payment_link_redirect_ability?: boolean;
    paymentLinkEnableBannerUpload?: string | null;
}

export enum QSRPrintLayoutEnum {
    Cashier = 'cashier',
    Kitchen = 'kitchen',
}

export enum QSRPrintSizeEnum {
    Normal = 'normal',
    Large = 'large',
}

export interface IPrinterConfig {
    printerIP: string;
    printerPort: string;
    printerEncrypt: boolean;
    printerDeviceId: string;
}

export interface IPrinter {
    id: string;
    name: string;
    status: PrintStatus;
    config: IPrinterConfig;
}

export enum PrintStatus {
    ENABLED = 'ENABLED',
    DISABLED = 'DISABLED',
}

export interface IVendorQsrOrderConfig {
    printerIP?: string;
    printerPort?: string;
    printerEncrypt?: boolean;
    printerDeviceId?: string;
    printerAutoPrintStatus?: string[];
    printerDisableAutoPrint?: boolean;
    printerLayout?: QSRPrintLayoutEnum[];
    printerSize?: { [key: string]: QSRPrintSizeEnum };
    printerEncoding?: string;
    printerImageBased?: boolean;
    printerLang?: string;
    printerNumberOfCopies?: number;
    vendorDatePresentation?: DatePresentationEnum;
}

export enum OrderPaymentMethodEnum {
    Default = 'default',
    PayLater = 'payLater',
    NoPayment = 'noPayment',
}

export enum OrderPricePresentationEnum {
    Default = 'default',
    HidePrice = 'hidePrice',
}

export enum DatePresentationEnum {
    Relative = 'relative',
    Full = 'full',
}

export enum OrderPriceRoundingModeEnum {
    Round = 'round',
    Floor = 'floor',
    Ceil = 'ceil',
}

export enum QsrCardViewIndicatorEnum {
    Table = 'table',
    Pager = 'pager',
    RefId = 'refId',
    Ticket = 'ticket',
    Payment = 'payment',
    PaxCount = 'paxCount',
}

export interface IOrderConfig extends IVendorQsrOrderConfig {
    enablePagerAssignMessage?: boolean;
    pagerEnable?: boolean;
    disableEditMenu?: boolean;
    disableSyncMenu?: boolean;
    vendorQsrCardViewEnable?: boolean;
    vendorQsrCardViewActions?: OrderStatusEnum[];
    vendorQsrCardViewPendingStatuses?: OrderStatusEnum[];
    vendorQsrCardViewFailedSectionEnable?: boolean;
    vendorQsrCardViewUserInfoField?: string[];
    vendorQsrCardViewHiddenIndicators?: QsrCardViewIndicatorEnum[];
    paymentMethod?: OrderPaymentMethodEnum;
    pricePresentation?: OrderPricePresentationEnum;
    defaultOrderStatus?: OrderStatusEnum;
    multiOrder?: boolean;
    qrPasscodeEnable?: boolean;
    qrDynamicPasscodeEnable?: boolean;
    qrDynamicPasscodeTTL?: number;
    qrDynamicTableDataEnable?: boolean;
    qrTableDataEnable?: boolean;
    fetchOrderInterval?: number;
    disabledMenuPreview?: boolean;
    priceRoundingMode?: OrderPriceRoundingModeEnum;
    customerExtraFields?: any;
    scheduledOrderEnable?: boolean;
    inventoryEnable?: boolean;
    pickUpOrderEnable?: boolean;
    diningOptionEnable?: boolean;
    diningOptionCustomText?: string;
    vendorEditOrderEnable?: boolean;
    vendorCreateOrderEnable?: boolean;
    skipPosSubmitOrder?: boolean;
    printerV2?: boolean;
    printerV1?: boolean;
    enableWhatsappMsg?: boolean;
    defaultWhatsappMsg?: string;
}

export interface ITableParams {
    id: string;
    f1?: any;
    f2?: any;
    hash?: string;
    name?: string;
    url?: string;
}

export interface IRestaurantCountryConfig {
    showDinerFeeTooltip?: string;
    billPageDescriptionMode?: boolean;
    compactInvoiceTable: boolean;
    defaultLang: string;
    newEmailServer: boolean;
    showAddressLine2: boolean;
    showArea: boolean;
    showGst: boolean;
    showOrderSubtotal: boolean;
    showTax: boolean;
    showTip: boolean;
    showVat: boolean;
    splitMode: string;
    zendeskKey: string;
    dinerFeeVisibility: IDinerFeeOption[];
    vendorOrderKeys: string[];
    vendorPaymentKeys: string[];
    isGoogleCurrencyFormatEnabled: boolean;
    disableTipCardAndChart: boolean;
    newSidebarEnabled: boolean;
    whatsappHelpNumber: string;
    vendor_enableOrderSearch: boolean;
    bookedAmount: number;
    reviewMetricsEnabled: boolean;
    enableLinkit?: 'show' | 'hide';
    paymentLinkCustomerCommissionAlias?: string;
    paymentLinkMaxAmount?: number;
    receiptTitle?: string;
    enableExternalPaidFlagInTableView?: string;
    paymentLinkEnableBannerUpload?: boolean;
}

export interface IRestaurantCountry {
    code: string;
    currency_code: string;
    currency_symbol: string;
    enabled: boolean;
    iso_code: string;
    language_code: string;
    name: string;
    phone_prefix: string;
    config: IRestaurantCountryConfig;
}

export enum RestaurantOrderMode {
    Default = 'default',
    DigitalMenu = 'digital_menu',
    QSR = 'qsr',
    Mixed = 'mixed',
}

export interface IRestaurant {
    id: string;
    restaurant_unique: string;
    name: string;
    brand: {
        created_at: string;
        deleted_at: null;
        email: string;
        id: string;
        name: string;
        pos_config: unknown;
        updated_at: string | null;
        verified: boolean | null;
        config: {
            googleReviewEnabled: boolean;
            isReviewPremium: boolean;
        };
    };
    brand_id: string;
    phone: string;
    email: string;
    title: string;
    address1: string;
    address2: string;
    area: string;
    city: string;
    country: string;
    external_menu_url: string;
    external_menu_format?: any;
    primary_colour: string;
    secondary_colour: string;
    background_url: string;
    logo_big_url: string;
    logo_small_url: string;
    country_code: string;
    restaurant_pos_access_id: string;
    google_place_id: string;
    ops_mode: string;
    timezone: string;
    timezoneName?: string;
    config: IConfig;
    created_at: Date;
    updated_at: Date;
    deleted_at?: any;
    restaurant_country: null | IRestaurantCountry;
    restaurant_timezone: null | { timezone: string };
    pos_vendor?: string;
    posAccess?: {
        id: string;
        pos_vendor_data: any;
        posVendor: {
            pos_vendor_name: string;
        };
    };
    order_mode?: RestaurantOrderMode;
    order_config?: IOrderConfig;
    qr_groups?: IQrRow[];
    brand_verification_sent?: boolean;
}

export interface ITinyRestaurant {
    id: string;
    country_code: string;
    restaurant_unique: string;
    name: string;
    title: string;
    logo_big_url: string;
    logo_small_url: string;
    pos_vendor_name: string;
}

export interface IRestaurantTables {
    id: string;
    restaurant_id: string;
    version: number;
    url: string;
    params: ITableParams;
    created_at: Date;
    updated_at: Date;
    deleted_at?: any;
    restaurant: IRestaurant;
}

export interface IRequestData {
    page: number;
    limit: number;
    startDate?: string | null;
    endDate?: string | null;
    period?: TimeRangeTypes;
}

export interface ITableTransaction {
    transaction: ITransaction;
    tip: number;
    paidAmount: number;
    customerCommission: number;
    customerPaid: number;
    paymentStatus: PaymentStatus;
    remaining: number;
    maxDate: Date | null;
    ago: string;
    voucherAmount: number;
    loyaltyAmount: number;
    bookedAmount: number;
    discount: number;
}

export interface ITableTransactionExtended extends ITableTransaction {
    url: string;
}

export interface ITableItem {
    table: ITableParams;
    transactions: ITableTransaction[];
}

export interface IGetRestaurantPosRes {
    integrated: boolean;
    branchId?: string;
    paymentId?: string;
    base_url?: string;
    authorization_base_url?: string;
    client_id?: string;
    redirect_url?: string;
}

export interface IQSRGetRestaurantPosRes {
    qsr_integrated: boolean;
    qsr_branchId?: string;
    qsr_paymentId?: string;
    qsr_base_url?: string;
    qsr_client_id?: string;
    qsr_redirect_url?: string;
}

export interface IGetBrandPosRes {
    integrated: boolean;
}

export interface IQSRGetBrandPosRes {
    qsr_integrated: boolean;
}

export interface IGetRestaurantPosBranchRes {
    list: IRestaurantPosBranch[];
}

export interface IRestaurantPosBranch {
    id: string;
    title: string;
    address: string;
}

export interface IGetRestaurantPosPaymentRes {
    list: IRestaurantPosPayment[];
}

export interface IRestaurantPosPayment {
    id: string;
    type: number;
    name: string;
}

export interface ISelectedTable {
    ids: string[];
    qrs: string[];
    qrGroups: string[];
}
