import { useMemo, useState } from 'react';
import { useLanguageQuery } from 'next-export-i18n';
import { TableColumn } from 'react-data-table-component';

import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import InfoOutlined from '@mui/icons-material/InfoOutlined';
import LocalOffer from '@mui/icons-material/LocalOffer';
import Paid from '@mui/icons-material/Paid';

import { onPushEvent } from '@/services/lib/gtm';
import { useTranslation } from '@/hooks/translations';
import { useRestaurantContext } from '@/contexts/restaurant';
import StatusChip from '@/components/PaymentLink/StatusChip';
import { IDinerFeeOption } from '@/views/OrdersTableView/types';
import type { DataRow, IRows, ReceiptDataIds } from './types';

interface MyTableColumn extends TableColumn<DataRow> {
    key: string;
}

export const useColumns = ({
    currencyCode,
    currencySymbol,
    showOrderInfo,
    setHasVoucher,
    setHasLoyalty,
    setIds,
    setDetailModal,
}: IRows) => {
    const { t } = useTranslation('common');
    const [query] = useLanguageQuery();
    const lang = query ? query.lang : 'en';
    const { restaurant } = useRestaurantContext();

    const theme = useTheme();
    const tablet = useMediaQuery(theme.breakpoints.down('lg'));
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    const showGrossTip = restaurant?.posAccess?.pos_vendor_data?.tipsSubmitMode === 'gross';
    const showQlubDinerFee =
        !restaurant?.restaurant_country?.config?.dinerFeeVisibility ||
        (!restaurant?.restaurant_country?.config?.dinerFeeVisibility?.includes(IDinerFeeOption.HideAll) &&
            !restaurant?.restaurant_country?.config?.dinerFeeVisibility?.includes(IDinerFeeOption.HideOrders));

    const getKeyText = (key: string) => {
        switch (key) {
            default:
            case 'table_id':
                return 'Table ID';
            case 'customer_amount':
                return 'Customer Paid';
            case 'table_name':
                return 'Table Name';
            case 'bill_amount':
                return 'Bill Amount';
            case 'qlub_discount':
                return 'Qlub Discount';
            case 'paid_amount':
                return 'Paid Amount';
            case 'tip_amount':
                return 'Tip Amount';
            case 'qlub_diner_fee':
                return 'Qlub Diner Fee';
            case 'remaining':
                return 'Remaining';
            case 'refunded':
                return 'Refunded';
            case 'date':
                return 'Date';
            case 'status':
                return 'Status';
            case 'pos_order_id':
                return 'Pos OrderId';
            case 'details':
                return 'details';
            case 'bookedAmount(paid+tip)':
                return 'bookedAmount(paid+tip)';
            case 'external_order_number':
                return 'External Order Number';
        }
    };

    const open = (data: ReceiptDataIds) => {
        setIds(data);
        setDetailModal(true);
    };

    const detailsAndDownloadItems: MyTableColumn[] = [
        {
            name: t('Details'),
            cell: (row: DataRow) => (
                <Button
                    onClick={() => {
                        open({ tableSessionId: row.id, paymentRecordId: row.paymentRecord?.[0]?.id || '' });
                        setHasVoucher(row.order?.voucher !== '-');
                        setHasLoyalty(row.order?.loyalty !== '-');
                        onPushEvent('user_click_order_details');
                    }}
                    sx={{
                        textTransform: 'none',
                        color: row.order?.voucher === '-' && row.order?.loyalty === '-' ? '#A9A9A9' : '#FF0000',
                        margin: '0',
                        padding: '6px 0',
                        fontSize: '1em',
                    }}
                    startIcon={<InfoOutlined />}
                >
                    {(!tablet || mobile) && t('details')}
                </Button>
            ),
            compact: true,
            omit: mobile,
            key: 'details',
            minWidth: '64px !important',
            selector: (row: DataRow) => row.id,
        },
    ];
    const mobileMustColumns: MyTableColumn[] = [
        {
            name: t('Table ID'),
            selector: (row: DataRow) => row.table_id,
            compact: tablet,
            center: true,
            key: 'table_id',
            omit: !mobile,
            minWidth: mobile ? '1px !important' : undefined,
        },
        {
            name: t('Customer Paid'),
            selector: (row: DataRow) => {
                return row.order?.customerPaid || '';
            },
            compact: tablet,
            key: 'customer_amount',
            omit: !mobile,
        },
    ];
    const columns: MyTableColumn[] = [
        {
            name: t('Table ID'),
            cell: (row: DataRow) => {
                const [tooltipOpen, setTooltipOpen] = useState(false);
                return (
                    <Tooltip
                        title={row.table_id}
                        placement="right"
                        arrow
                        sx={{
                            color: '#000000',
                        }}
                        onClick={() => {
                            setTooltipOpen(true);
                        }}
                        onTouchMove={() => {
                            setTooltipOpen(true);
                        }}
                        onClose={() => {
                            setTooltipOpen(false);
                        }}
                        open={tooltipOpen}
                    >
                        <Typography
                            sx={{
                                fontSize: '13px',
                                color: '#000000',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: '70px',
                            }}
                        >
                            {row.table_id}
                        </Typography>
                    </Tooltip>
                );
            },
            compact: tablet,
            key: 'table_id',
            omit: mobile,
            minWidth: mobile ? '1px !important' : undefined,
        },
        {
            name: t('Table Name'),
            cell: (row: DataRow) => {
                const [tooltipOpen, setTooltipOpen] = useState(false);
                return (
                    <Tooltip
                        title={row.table_data.name}
                        placement="right"
                        arrow
                        sx={{
                            color: '#000000',
                        }}
                        onClick={() => {
                            setTooltipOpen(true);
                        }}
                        onTouchMove={() => {
                            setTooltipOpen(true);
                        }}
                        onClose={() => {
                            setTooltipOpen(false);
                        }}
                        open={tooltipOpen}
                    >
                        <Typography
                            sx={{
                                fontSize: '13px',
                                color: '#000000',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: '70px',
                            }}
                        >
                            {row.table_data.name}
                        </Typography>
                    </Tooltip>
                );
            },
            compact: tablet,
            key: 'table_name',
            omit: mobile,
        },
        {
            name: t('Bill Amount'),
            selector: (row: DataRow) => {
                return row.order?.bill || '';
            },
            compact: tablet,
            key: 'bill_amount',
            omit: mobile,
        },
        {
            name: t('Qlub Discount'),
            selector: (row: DataRow) => {
                return row.order?.discount || '';
            },
            compact: tablet,
            key: 'qlub_discount',
            omit: mobile,
        },
        // {
        //     name: t('Voucher'),
        //     selector: (row: DataRow) => {
        //         return row.order?.voucher || '';
        //     },
        //     omit: mobile,
        // },
        {
            name: t('Paid Amount'),
            selector: (row: DataRow) => {
                return row.order?.paid || '';
            },
            compact: tablet,
            key: 'paid_amount',
            omit: mobile,
        },
        {
            name: t('Tip Amount'),
            selector: (row: DataRow) => {
                if (showGrossTip) {
                    return row.order?.grossTip || '';
                }
                return row.order?.netTip || '';
            },
            compact: tablet,
            key: 'tip_amount',
            omit: mobile,
        },
        ...(showQlubDinerFee
            ? [
                  {
                      name: t('Qlub Diner Fee'),
                      selector: (row: DataRow) => {
                          return row.order?.customerCommission || '';
                      },
                      compact: tablet,
                      omit: mobile,
                      key: 'qlub_diner_fee',
                  },
              ]
            : []),
        {
            name: t('Customer Paid'),
            selector: (row: DataRow) => {
                return row.order?.customerPaid || '';
            },
            compact: tablet,
            key: 'customer_amount',
            omit: mobile,
        },
        {
            name: t('Booked Amount'),
            selector: (row: DataRow) => {
                return row.order?.customerPaidExcludingDinerFee || '';
            },
            key: 'bookedAmount(paid+tip)',
            omit: mobile,
            wrap: true,
        },
        {
            name: t('Remaining'),
            selector: (row: DataRow) => {
                return row.order?.remaining || '';
            },
            key: 'remaining',
            omit: mobile,
        },
        {
            name: t('Refunded'),
            selector: (row: DataRow) => {
                return row.order?.refunded || '';
            },
            key: 'refunded',
            omit: mobile,
        },
        {
            name: t('Date'),
            selector: (row: DataRow) => {
                return row.order?.date || '';
            },
            minWidth: '165px',
            key: 'date',
            omit: mobile,
            wrap: true,
        },
        {
            name: t('Status'),
            cell: (row: DataRow) => (
                <Badge
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    color="secondary"
                    badgeContent={
                        row.order?.voucher !== '-' ? (
                            <>
                                <LocalOffer sx={{ width: '10px', height: '10px' }} />
                                <Typography
                                    sx={{
                                        height: '24px',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        fontSize: '8px',
                                        lineHeight: '140%',
                                        textAlign: 'center',
                                        letterSpacing: '0.25px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    {t('Voucher')}
                                </Typography>
                            </>
                        ) : row.order?.loyalty !== '-' ? (
                            <>
                                <Paid sx={{ width: '13px', height: '13px' }} />
                                <Typography
                                    sx={{
                                        paddingLeft: '1px',
                                        height: '24px',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        fontSize: '8px',
                                        lineHeight: '140%',
                                        textAlign: 'center',
                                        letterSpacing: '0.25px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    {t('Loyalty')}
                                </Typography>
                            </>
                        ) : row.order?.discount !== '-' &&
                          row.paymentRecord?.some(
                              (pr) => pr.extra_info?.loyalty_redemption?.discountType === 'General',
                          ) ? (
                            <>
                                <LocalOffer sx={{ width: '10px', height: '10px' }} />
                                <Typography
                                    sx={{
                                        paddingLeft: '1px',
                                        height: '24px',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        fontSize: '8px',
                                        lineHeight: '140%',
                                        textAlign: 'center',
                                        letterSpacing: '0.25px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    {t('Discount')}
                                </Typography>
                            </>
                        ) : (
                            0
                        )
                    }
                    showZero={false}
                >
                    <StatusChip
                        status={
                            row.order?.status === 'paid'
                                ? 'paid'
                                : row.order?.status === 'paid_partially'
                                ? 'partially_paid'
                                : row.order?.status
                        }
                    />
                </Badge>
            ),
            compact: tablet,
            key: 'status',
            omit: mobile,
            minWidth: '140px',
        },
        {
            name: t('Pos OrderId'),
            selector: (row: DataRow) => {
                if (row?.pos_extra_info?.pos_order_id) {
                    return row?.pos_extra_info?.pos_order_id;
                }

                return false;
            },
            omit: !showOrderInfo || mobile,
            compact: tablet,
            key: 'pos_order_id',
        },
        {
            name: t('External Order Number'),
            selector: (row: DataRow) => {
                return row.order_data?.metadata?.invoiceNumber || '';
            },
            key: 'external_order_number',
            compact: tablet,
        },

        ...detailsAndDownloadItems,
    ];
    return useMemo(() => {
        const configOrderKeys = restaurant?.config.vendorOrderKeys || [];
        const countryOrderKeys = restaurant?.restaurant_country?.config?.vendorOrderKeys || [];
        const defaultColumns = columns.map((col) => {
            return getKeyText(col.key);
        });

        const vendorOrderKeys =
            configOrderKeys.length > 0
                ? configOrderKeys
                : countryOrderKeys.length > 0
                ? countryOrderKeys
                : defaultColumns;

        const mustColumns = ['Status', 'pos_order_id', 'details'];
        const allKeys = [...new Set([mustColumns[1], ...vendorOrderKeys, mustColumns[0], mustColumns[2]])];

        const getColumn = (key: string): MyTableColumn | undefined => {
            return columns.find((col) => key.toLowerCase() === getKeyText(col.key).toLowerCase());
        };

        let newColumns = allKeys.map(getColumn).filter((col): col is MyTableColumn => col !== undefined);

        if (mobile) {
            const mobileMustColumnKeys = mobileMustColumns.map((col) => col.key);
            newColumns = [...mobileMustColumns, ...newColumns.filter((col) => !mobileMustColumnKeys.includes(col.key))];
        }

        return [...newColumns];
    }, [lang, currencyCode, currencySymbol, showOrderInfo, setIds, setDetailModal, tablet, mobile, restaurant]);
};
