export function formatMobile(mobile: string) {
    const digits = mobile.replace(/\D/g, '');
    return mobile.startsWith('+') ? `+${digits}` : digits;
}

export function whatsAppPaymentLink(mobile: string, link: string) {
    const formattedMobile = formatMobile(mobile);
    window.open(
        `https://api.whatsapp.com/send?phone=${formattedMobile}&text=There%20is%20a%20payment%20request%20for%20you.%20Please%20tap%20on%20the%20below%20link%20to%20pay.%20%0A${'```'}${link}${'```'}`,
        '_blank',
    );
}

export function whatsAppDefaultMessage(mobile: string, message: string) {
    const formattedMobile = formatMobile(mobile);
    window.open(`https://api.whatsapp.com/send?phone=${formattedMobile}&text=${encodeURIComponent(message)}`, '_blank');
}

export function parseMultiLocaleMessage(message: string | undefined, currentLang: string) {
    if (!message) return '';
    try {
        const messages = JSON.parse(message);
        if (!Array.isArray(messages)) return message;

        const localeMessage =
            messages.find((msg: any) => msg.locale === currentLang) || messages.find((msg: any) => msg.locale === 'en');
        return localeMessage?.value || '';
    } catch {
        return message;
    }
}
